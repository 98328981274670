import { AppBar} from '@mui/material';
import React from 'react';
import "./layout.css"
import useScrollNavbar from '../hooks/useScrollNavbar';
import { useThemeSetting } from '../features';

/**
 * Custom component for a scrollable navbar.
 *
 * @param {object} props - Component props.
 * @param {number} [props.scrollTrigger=40] - The scroll trigger value.
 * @param {object} props.sx - Custom styles for the navbar.
 * @param {React.ReactNode} props.children - The content inside the navbar.
 * @return {React.JSX.Element} The custom scrollable navbar component.
 */

const CustomScrollNavbar = ({scrollTrigger = 40, sx = {}, children}) => {
    const { isScrolled } = useScrollNavbar(scrollTrigger);
    const {mode} = useThemeSetting();
    return (
      <AppBar
      sx={{
          ...sx,
          backgroundColor: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
          boxShadow: isScrolled ? "0px 4px 16px rgba(43, 52, 69, 0.1)" : '0px 2px 1px rgba(0, 0, 0, 0.08)',
        }}
        className={isScrolled ? 'fixedHeader' : ''}
        elevation={isScrolled ? 1 : 0}
        component={'header'}
      >
            {children}
      </AppBar>
    );
}
export default CustomScrollNavbar