import { useDispatch } from "react-redux";
import { closeDrawer, openDrawer } from "./_reducers";

const useDrawerAction = () => {
    const dispatch = useDispatch();

    const onOpenDrawer = () => {
        dispatch(openDrawer())
    }

    const onCloseDrawer = () => {
        dispatch(closeDrawer())
    }

    return {
        onOpenDrawer,
        onCloseDrawer
    }
}

export default useDrawerAction