import React from 'react';
import "@fontsource/roboto"
// import NotoSan from '../assets/fonts/NotoSans/NotoSansMyanmar-Regular.woff2';
import { createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { useThemeSetting } from '../features';

const RobotoFont = {
    fontFamily: 'Roboto',
};

// const MMFont = {
//     fontFamily: 'Roboto',
//     fontStyle: 'normal',
//     fontDisplay: 'swap',
//     fontWeight: 400,
//     src: `local('NotoSansMyanmar'),
//     local('NotoSansMyanmar-Regular'),
//     url(${NotoSan}) format('woff2')`,
//         unicodeRange:
//             'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// }

const CustomThemeProvider = (props) => {
    const { mode } = useThemeSetting();
    console.info(`Custom Theme Mode : ${mode}`);
    console.info("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -")
    const _theme = React.useMemo(() => createTheme({
        spacing: 4,
        mixins:
        {
              toolbar: { minHeight: 60 },
              '@media (min-width:0px)': { minHeight: 60 },
              '@media (min-width:600px)': { minHeight: 60 },
        },
        typography: {
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
            h1: {

                fontSize: '56px',
                fontWeight:  700,
                lineHeight: 1.5
            },
            h2: {

                fontSize: '46px',
                fontWeight:  700,
            },
            h3: {
                fontSize : '24px',
                fontWeight:  500,
            },
            h4: {

                fontSize: '24px',
                fontWeight:  400,
                lineHeight: 1.3
            },
            h5: {

                fontSize: '20px',
                fontWeight:  400,
            },
            h6: {

                fontSize: '16px',
                fontWeight:  500,
            },
            body1: {

                fontSize: '14px',
                fontWeight:  400,
            },
            body2: {

                fontSize: '14px',
                fontWeight:  400,
                lineHeight: 1.5,
            },
            subtitle1: {
                
                fontSize: '12px',
                fontWeight: 400,
            },
            subtitle2: {
                
                fontSize: '12px',
                fontWeight: 400,
                color: mode === 'light' ? '#677788' : '#aeb0b4'
            },
            button: {

                fontSize: '16px',
                fontWeight: 400,
                textTransform: 'none',
            },
            caption: {
                
                fontSize: '0.75rem',
                fontWeight: 400,
            },
            caption2: {
                
                fontSize: '0.75rem',
                fontWeight: 400,
                color: mode === 'light' ? '#677788' : '#aeb0b4'
            },
            overline: {
                
            }
        },
        shape: {
            borderRadius: 5
        },
        palette: {
            mode,
            common: {
                black: '#1E1E1E',
                white: '#FFFFFF',
            },
            primary: {
                main: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
                contrastText: "#1E1E1E",
            },
            dark : {
                main : mode === 'light' ? '#1E1E1E' : '#FFFFFF',
            },
            background: {
                paper: mode === 'light' ? '#ffffff' : '#1E1E1E',
                default: mode === 'light' ? '#ffffff' : '#1E1E1E',
            },
        },
        components: {
            MuiCssBaseline : {
                styleOverrides: {
                    '@global': {
                        '@font-face': [RobotoFont],
                        html: {
                            WebkitFontSmoothing: 'antialiased', // Font Smoothing for WebKit browsers
                            MozOsxFontSmoothing: 'grayscale', // Font Smoothing for Firefox on macOS
                            textRendering: 'optimizeLegibility', // Optimize text legibility
                            WebkitOverflowScrolling: 'touch', // Enable touch-based overflow scrolling for WebKit browsers
                        },
                        body: {
                            WebkitFontSmoothing: 'antialiased',
                            MozOsxFontSmoothing: 'grayscale',
                            textRendering: 'optimizeLegibility',
                            WebkitOverflowScrolling: 'touch',
                        },
                        '#root': {
                            WebkitFontSmoothing: 'antialiased',
                            MozOsxFontSmoothing: 'grayscale',
                            textRendering: 'optimizeLegibility',
                            WebkitOverflowScrolling: 'touch',
                        },
                        '*::-webkit-scrollbar': {
                            width: '0em'
                        },
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        minWidth: '200px',
                        padding: '8px 20px',
                        transition: 'all 250ms ease-in-out',
                        borderRadius: 50
                  },
                  contained: {
                       backgroundColor: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
                       color: mode === 'light' ? '#1E1E1E' : '#FFFFFF',
                       '&:hover' : {
                        backgroundColor: mode === 'light' ? '#1E1E1E' : '#FFFFFF',                        
                        color: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
                       }
                  },
                  outlined: {
                        boxShadow: 'none',
                        borderColor: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
                        color: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
                        '&:hover': {
                            backgroundColor: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
                            color: mode === 'light' ? '#1E1E1E' : '#FFFFFF',
                        }
                  },
                  text: {
                    boxShadow: 'none',
                    color: mode === 'light' ? '#1E1E1E' : '#FFFFFF',
                    '&:hover' : {
                        boxShadow: 'none',
                        color: mode === 'light' ? '#1E1E1E' : '#FFFFFF' 
                    }
                  }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        width: '50px',
                        height: '50px',
                        marginBottom: '16px',
                        fontSize: '1.25rem'
                  }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        '@media (min-width:0px)': { paddingLeft: '0px', paddingRight: '0px' },
                        '@media (min-width:600px)': { paddingLeft: '0px', paddingRight: '0px' },
                    }
                }
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        '@media (min-width:0px)': { paddingLeft: '0px', paddingRight: '0px' },
                        '@media (min-width:600px)': { paddingLeft: '0px', paddingRight: '0px' },
                    }
                }
            }
        }
    }),[mode])

    const ThemeWithRepositiveFont = responsiveFontSizes(_theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={ThemeWithRepositiveFont}>
                {props.children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default CustomThemeProvider

