import React, { useState } from 'react'
import CustomScrollNavbar from './CustomScrollNavbar'
import { Box, Container, IconButton, Stack, Switch, Toolbar, Typography } from '@mui/material'
import useMediaQueryDevice from '../hooks/useMediaQueryDevice'
import { content, nav_links } from '../config/_nav_link'
import { Close, Email, Phone } from '@mui/icons-material'
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { useDrawer, useThemeSetting } from '../features'
import "./layout.css"
import Logo from '../components/Logo'
import useScrollNavbar from '../hooks/useScrollNavbar'
import { Menu } from '@mui/icons-material'
import CustomDrawer from './CustomDrawer'
import { FaceBookIcon, LinkedInIcon } from '../components/Icons'

const Navbar = () => {
  const {tablet} = useMediaQueryDevice();
  const {isScrolled} = useScrollNavbar(40);
  const {mode, changeMode} = useThemeSetting();
  const {onCloseDrawer, onOpenDrawer, open} = useDrawer();
  const [isAcitve, setIsActive] = useState();


  const contentUs = [
    {
      type : 'tel',
      label : 'Call us: ',
      value : content.phone,
      icon : <Phone/>
    },
    {
      type : 'mailto',
      label: 'Send us an email: ',
      value: content.email,
      icon : <Email/>
    }
  ]

  return (
    <CustomScrollNavbar scrollTrigger={40}>
        <Container maxWidth="lg">
            <Box sx={{px: 6}}>
              {
                tablet ? (
                  <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none', color : mode === 'dark' && isScrolled ? '#1E1E1E' : 'inherit'}}>
                      <Stack direction={'row'} gap={6} sx={{textTransform: 'uppercase'}} alignItems={"center"}>
                        <Logo/>
                        {nav_links.map((item, index) => (
                          <a key={index} href={item.href} onClick={() => setIsActive(item.href)} className={isAcitve === item.href ? 'nav_link active' : 'nav_link'}>
                            <Typography variant="body2" sx={{ fontWeight: 500, color: 'inherit'}}>
                              {item.label}
                            </Typography>
                          </a>
                        ))}
                      </Stack>
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={6}>
                          <a href={`tel:${content.phone}`} target="_blank" rel="noopener noreferrer">
                            <Typography variant="h6">
                              Call us: {content.phone}
                            </Typography>
                          </a>
                          <FaceBookIcon/>
                          <LinkedInIcon/>
                          <Box component={'div'} sx={{cursor: 'pointer', width: '26px', height: '26px'}} onClick={changeMode}>
                            <WbSunnyIcon sx={{fontSize: '1.6rem', color: mode === 'dark' && isScrolled ? '#1E1E1E' : 'inherit'}}/>
                          </Box>
                      </Stack>
                  </Toolbar>
                ) : (
                  <Box>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Logo/>
                      <IconButton size='small' onClick={onOpenDrawer} sx={{ color: mode === 'dark' && isScrolled ? '#1E1E1E' : 'inherit', minWidth: '40px', minHeight: '40px', p: 2, mt: 0, borderRadius: '50%', '&:hover' : {backgroundColor: '#0000000a'}}}>
                        <Menu fontSize='large'/>
                      </IconButton>
                    </Stack>
                    <CustomDrawer open={open} onClose={onCloseDrawer} minWidth='300px'>
                        <Box sx={{p: 10}}>
                            <Stack direction='row' justifyContent={'space-between'} spacing={8} sx={{textTransform: 'uppercase'}} alignItems={"center"}>
                              <Logo/>
                              <IconButton onClick={onCloseDrawer} size='small'>
                                <Close fontSize='small'/>
                              </IconButton>
                            </Stack>
                            <Box sx={{my : 10, width: '30%', display: 'flex', flexDirection: "column", gap: '2rem'}}>
                              {nav_links.map((item, index) => (
                                <a key={index} onClickCapture={onCloseDrawer} href={item.href} onClick={() => setIsActive(item.href)} className={isAcitve === item.href? 'nav_link active' : 'nav_link'}>
                                  <Typography variant="h6" sx={{ fontWeight: 500, color: 'inherit'}}>
                                    {item.label}
                                  </Typography>
                                </a>
                              ))}
                            </Box>
                            <Stack direction={'column'} spacing={8}>
                              {
                                contentUs.map(item => (
                                  <Stack key={item.label} direction={'row'} alignItems={'center'} spacing={3} sx={{'&:hover' : {color: 'darkgray'}, transition: '0.3s all ease-in-out'}}>
                                      {item.icon}
                                      <a href={`${item.type}:${content.phone}`} target="_blank" rel="noopener noreferrer">
                                        <Typography variant="h6">
                                          {item.label} {item.value}
                                        </Typography>
                                      </a>
                                  </Stack>
                                ))
                              }
                              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant='h6'>
                                  Dark Mode
                                </Typography>
                                <Switch defaultChecked={mode === 'dark' ? true : null} onChange={changeMode}/>
                              </Stack>
                            </Stack>
                        </Box>
                    </CustomDrawer>
                  </Box>
                )
              }
            </Box>
        </Container>
    </CustomScrollNavbar>
  )
}

export default Navbar

