import { useMediaQuery } from '@mui/material'

const useMediaQueryDevice = () => {
    const mobile = useMediaQuery('(max-width: 600px)');
    const tablet = useMediaQuery('(min-width: 900px)');
    const desktop = useMediaQuery('(min-width: 1280px)');

    return {
        mobile,
        tablet,
        desktop
    }
}

export default useMediaQueryDevice