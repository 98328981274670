export const nav_links = [
    {
        label : 'Services',
        href : '#services'
    },
    {
        label : 'About Us',
        href : '#about'
    }
]


export const content = {
    phone : '+959 761627418',
    facebook : 'https://www.facebook.com/profile.php?id=61556750216199&mibextid=JRoKGi',
    linkin : 'https://www.linkedin.com/company/caringcodecreation',
    email : 'hello@c3.com.mm'
}