import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import Logo from '../components/Logo'
import { FaceBookIcon, LinkedInIcon } from '../components/Icons'

const Footer = () => {
  return (
    <Box component={'footer'} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' ,minHeight: '174px', height: '100%', bgcolor: '#FFFFFF', color: '#1E1E1E', px: {xs: 6, md: 0}}}>
      <Container maxWidth="lg">
            <Box sx={{display: 'flex', flexDirection: {xs: 'column-reverse', md: 'row'}, gap: 6, justifyContent: 'space-between', px: {xs: '10px',  md: 0}}}>
                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                  <Logo height='22px'/>
                  <span>|</span>
                  <Typography sx={{fontSize: {md: '16px', xs: '12px'}}}>Copyright © 2024-2025 C3 Company Limited. All Rights Reserved. </Typography>
                </Stack>
                <Stack gap={6} direction={"row"} justifyContent={{xs: 'center', md: 'start'}} alignItems={'center'}>
                  <LinkedInIcon/>
                  <FaceBookIcon/>
                </Stack>
            </Box>
        </Container>
    </Box>
  )
}

export default Footer