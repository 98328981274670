import Img1 from "../assets/image/gp1.png"
import Img2 from "../assets/image/graph.png"

const services = [
    {
        title : "Flexi Plan",
        subTitle : 'Dedicated Team',
        description : "For long-term web application development projects , especially those with ongoing feature updates, hav-ing a dedicated team of web/mobile application developers is highly beneficial. You can establish a full-time team at C3 or complement your existing in-house team. Additionally, you can bring in specialized roles such as UI/UX design, DevOps, and Solution Architecture on an hourly basis as needed. This setup ensures focused attention on your project's evolving needs and enhances overall development efficiency.",
        img: Img1
    },
    {
        title : 'Fix Plan',
        subTitle: 'Fixed Price Project',
        description: 'If the scope of work is well-defined and you want to have certainty of the budget and timeline from the beginning, then a fixed price project will provide the financial predictability and clear expectations you need. However, it does not have to come at the cost of flexibility. We are happy to make adjustments for you along the way at no charge, with a zero-headache process for handling requests for more significant changes.',
        img : Img2
    }
]

export {
    services
}