import { useDispatch } from "react-redux"
import { changeThemeMode } from "./_reducers";

const useThemeAction = () => {
    const dispatch = useDispatch();

    const changeMode = () => {
        dispatch(changeThemeMode())
    }

    return {
        changeMode
    }
}

export default useThemeAction