import { Box, Drawer } from '@mui/material'
import React from 'react'

const CustomDrawer = ({open, onClose, minWidth = '250px', children}) => {
  return (
    <Drawer open={open} onClose={onClose} role='presentation' anchor='right'>
        <Box sx={{minWidth: minWidth}}>
            {children}
        </Box>
    </Drawer>
  )
}

export default CustomDrawer