import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mode : JSON.parse(localStorage.getItem('company')) ?? 'light'

}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeThemeMode : (state) => {
            state.mode = state.mode === 'light' ? 'dark' : 'light';
            localStorage.setItem('company', JSON.stringify(state.mode))
        }
    }
})

export const { changeThemeMode } = themeSlice.actions

export default themeSlice.reducer