import { Box, Container } from "@mui/material"
import HeroSection from "./HeroSection"
import ServiceSection from "./ServiceSection"
import AboutUsSection from "./AboutUsSection"
import ContentSection from "./ContentSection"

const Portfolio = () => {
  return (
    <Box component={'main'}>
        <Box component={'section'} id="home">
          <Container maxWidth="lg" sx={{py: 15}}>
              <HeroSection/>
          </Container>
        </Box>
        <Box component={'section'} id="services" sx={{bgcolor: 'dark.main', color: 'primary.main', py: 15}}>
          <ServiceSection/>
        </Box>
        <Box component={'section'} id="about">
          <AboutUsSection/>
        </Box>
        <Box component={'section'} id="content" sx={{bgcolor: '#1E1E1E', color: 'primary.main'}}>
          <ContentSection/>
        </Box>
    </Box>
  )
}

export default Portfolio