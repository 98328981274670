import React from 'react'
import { Link } from 'react-router-dom'
import { content } from '../config/_nav_link'
import FaceBook from "../assets/image/facebook.png"
import LinkInDark from "../assets/image/Vector.png"
import LinkedIn from "../assets/image/linkin.png"

const img = (src) => (<img src={src} alt='icon' width={'100%'} height={'100%'}/>)

const FaceBookIcon = () => {
  return (
    <Link to={content.facebook} target='_blank' style={{width: '26px', height: '26px'}}>
        {img(FaceBook)}
    </Link>
  )
}

const LinkInDarkIcon = () => {
  return (
    <Link to={content.linkin} target='_blank' style={{width: '26px', height: '26px'}}>
      {img(LinkInDark)}
    </Link>
  )
}


const LinkedInIcon = () => {
  return (
    <Link to={content.linkin} target='_blank' style={{width: '26px', height: '26px'}}>
      {img(LinkedIn)}
    </Link>
  )
}

export { FaceBookIcon, LinkInDarkIcon, LinkedInIcon }