import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { drawerReducer, themeSettingReducer } from "./features";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
    key: 'root',
    storage
}

const reducers = combineReducers({
    theme : themeSettingReducer,
    drawer : drawerReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer
})

export const persistor = persistStore(store)