import React from 'react'
import ImgLogo from "../assets/image/logoC3.png"
import LogoWhite from "../assets/image/logoWhite.png"
import { useDrawer, useThemeSetting } from '../features'
import useScrollNavbar from '../hooks/useScrollNavbar'

const Logo = ({type, height = '36px'}) => {
  const {mode} = useThemeSetting();
  const {isScrolled} = useScrollNavbar(40);
  const {onCloseDrawer} = useDrawer();
  return (
    <a 
      href='#home'
      onClick={onCloseDrawer}
      style={{height: height}}
    >
      <img 
        src={type !== 'footer' ? mode === 'dark' && isScrolled ? ImgLogo : mode === 'dark' ? LogoWhite : ImgLogo : ImgLogo }
        className="navlogo"
        alt="logo"
        width={'100%'}
        height={'100%'}
        style={{objectFit: 'contain'}}
      />
    </a>
  )
}

export default Logo