import { Box, Typography } from '@mui/material'
import React from 'react'
import lightIcons from "../assets/image/lightModeIcons.png"
import darkIcons from "../assets/image/darkModeIcons.png"
import { useThemeSetting } from '../features'

const AboutUsSection = () => {
  const {mode} = useThemeSetting();
  return (
      <Box sx={{px : {xs: 6, md: 0}}}>
          <Box sx={{px: {xs: '10px', md : '121px'}, py: 20, height: {md : '924px', xs: '100%'}, display: 'flex', flexDirection: 'column', gap: '24px'}}>
            <Typography variant='h2' textAlign={'center'}>Our Expertise</Typography>
            <Box sx={{width: {xs: '100%', md: '750px', lg: "926px"}, heigth: {xs: '100%', md: '450px', lg: "603px"}, mx: 'auto'}}>
              <img alt='icons' src={mode === 'light' ? lightIcons : darkIcons} style={{width: '100%', height: '100%'}}/>
            </Box>      
          </Box>
      </Box>
  )
}

export default AboutUsSection