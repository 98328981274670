import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { useThemeSetting } from '../features'
import { services } from '../config/_services';

const ServiceSection = () => {
  return (
    <Container maxWidth="lg" sx={{py: 6, px: {xs: 6, md: 0}}}>
      <Box sx={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '56px', px: {xs: '10px', md: 0}}}>
        <Typography textAlign={'center'} variant='h2'>Custom Software Development Services</Typography>
        <Typography variant='h4' textAlign={'center'}>You get plenty of flexibility when you partner with us here at C3. We provide web/mobile app development services to various types of companies, from early-stage startups to enterprises. We understand that each company has unique needs, so when choosing custom web application development services, it is important to pick the right engagement model.</Typography>
        <Box sx={{py: 8}}>
            <Grid container spacing={12}>
                {
                  services.map(item => (
                    <ServiceCard key={item.title} service={item}/>
                  ))
                }
            </Grid>
        </Box>
      </Box>
    </Container>
  )
}

const ServiceCard = ({service}) => {
  const {mode} = useThemeSetting();
  return (
    <Grid item xs={12} md={6} sx={{minHeight: '837px'}}>
       <Box component={Paper} elevation={0} sx={{width: '100%', height: '100%', display: 'flex', gap: '30px', alignItems: 'center', flexDirection: 'column', bgcolor: mode === 'light' ? '#1E1E1E' : '#FFFFFF', color: mode === 'light' ? '#FFFFFF' : '#1E1E1E'}}>
          <Box sx={{borderRadius: '50%', border: '1px solid #1e1e1e', p: 0, m: 0, width: '242px', height: '242px'}}>
           <img src={service.img} alt={service.title} style={{width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover', objectPosition: '90% 0px'}}/>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection : 'column'}}>
              <Typography variant='h2'>{service.title}</Typography>
              <Typography variant='h5'>{service.subTitle}</Typography>
          </Box>
          <Typography variant='h4' sx={{mt: 4}}>{service.description}</Typography>
          <Box>
            {/* <Button variant='outlined' sx={{mt: 4}}>
              Learn More
            </Button> */}
          </Box>
       </Box>
    </Grid>
  )
}

export default ServiceSection