import { useSelector } from 'react-redux'
import useThemeAction from './_actions'

export const useThemeSetting = () => {
    const {changeMode} = useThemeAction();

    const mode = useSelector((state) => state.theme.mode)

    return {
        changeMode,
        mode
    }
}

export { default as themeSettingReducer } from "./_reducers"