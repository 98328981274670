import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import BackgroundImage from "../assets/image/contact.png"
import { content } from '../config/_nav_link'

const ContentSection = () => {
  return (
    <Container>
        <Box sx={{position: 'relative', color: 'white', height: '350px', display:"flex", justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'contain', backgroundRepeat: 'no-repeat'}}>
            <Box sx={{textAlign: 'center', position: 'relative', zIndex: 2}}>
                <Typography variant='h3' sx={{fontSize: {md: '40px', xs: '25px'}}}>Hire Us</Typography>
                <a href='tel:+959 761627418'>
                    <Typography variant='h3' sx={{fontSize: {md: '40px', xs: '25px'}, my: 2}}>+959 761627418</Typography>
                </a>
                <a href={`mailto:${content.email}`}>
                    <Typography variant='h3' sx={{fontSize: {md: '40px', xs: '25px'}, borderBottom: '3px solid white'}}>{content.email}</Typography>
                </a>
            </Box>
        </Box>
    </Container>
  )
}

export default ContentSection