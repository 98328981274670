import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import HeroBanner from "../assets/image/IMG_3668 1.png"

const HeroSection = () => {
  return (
    <Box sx={{minHeight: '578px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Grid container alignItems={"center"} flexDirection={{xs: 'column-reverse', md: 'row'}} sx={{px: 6}}>
              <Grid item xs={12} md={6} sx={{px: '10px'}}>
                <Box sx={{width: {md: '471px', xs: '100%'}}}>
                    <Typography variant='h1'>Build your future with us!</Typography>
                    <Typography variant='h6'>
                      At C3 (Caring Code Creation), we believe in transforming ideas into impactful digital solutions. With a focus on innovation and efficiency, we offer a comprehensive suite of services tailored to guide your journey through the digital landscape. From conceptualization to execution, we're here to empower your vision every step of the way.
                    </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{px: '10px'}}>
                <Box sx={{width: '100%', position: 'relative', display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
                  <Box sx={{width: {xs: '100%', md: '503px'}}}>
                    <img src={HeroBanner} alt='img' style={{width: '100%', height: '368px', margin: '0 auto', objectFit: 'contain', position: 'relative', zIndex: 1}}/>
                  </Box>
                  <Bubble
                    size={{md: '50px', xs: '40px'}}
                    sx={{bottom : '50%', right: 0}}
                    zIndex={-1}
                    borderColor={'#1E1E1E24'}
                    backgroundColor={'#1E1E1E07'}
                  />
                  <Bubble
                    size={{md: '50px', xs: '40px'}}
                    sx={{bottom : {xs: 40, md: 0}, right: {xs: 100, md: 200}}}
                    zIndex={-1}
                    backgroundColor={'#1E1E1E55'}
                  />
                </Box>
              </Grid>
          </Grid>
    </Box>
  )
}

const Bubble = ({size, backgroundColor, borderColor, border, zIndex = 1, sx}) => {
  return (
    <Box sx={{...sx, position: 'absolute', width: size ?? '100px', height: size ?? '100px', borderRadius: '50%', backgroundColor: backgroundColor ?? 'transparent', border : border ? border : '1px solid', borderColor: borderColor ?? 'transparent', boxShadow: '0px 4px 16px rgba(43, 52, 69, 0.1)', zIndex: zIndex}}/>
  )
}

export default HeroSection