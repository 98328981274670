import React from 'react'
import Portfolio from './page'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import CustomThemeProvider from './config/_theme.config'
import { Box, CssBaseline } from '@mui/material'
import Navbar from './layout/Navbar'
import Footer from './layout/Footer'

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CustomThemeProvider>
          <CssBaseline/>
          <Box>
            <Navbar/>
            <Portfolio/>
            <Footer/>
          </Box>
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App